<template>
  <div class="fitness-goal">
    <v-row>
      <v-col>
        <h1 class="mb-4">Choose Your Fitness Goal</h1>
        <p>Fill in the number of classes you'll commit to and we'll show you what your donation of ${{ donationAmount }} will be once your DYP challenge is completed.</p>
      </v-col>
    </v-row>
    <v-row justify="center" class="mb-7" v-if="livemettaSelected">
      <v-col>
        <v-row justify="center" align="end">
          <v-col cols="7">
            <v-img src="/livemetta-horizontal.svg" />
          </v-col>
        
          <v-col cols="3" class="text-center">
            <h4 class="mb-2">Classes</h4>

            <v-text-field
              type="number"
              outlined
              hide-details
              v-model="pledgeCountLivemetta"/>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row justify="center" class="mb-7" v-if="rayogaSelected">
      <v-col>
        <v-row justify="center" align="end">
          <v-col cols="7">
            <v-img src="/rayoga-logo-brown.svg" />
          </v-col>
        
          <v-col cols="3" class="text-center">
            <h4 class="mb-2">Classes</h4>

            <v-text-field
              type="number"
              outlined
              hide-details
              v-model="pledgeCountRayoga"/>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="3">
        <h3>Your Donation</h3>
        <p class="amount-font">${{ donationAmount }}</p>
      </v-col>
      <v-col cols="3">
        <h3>Donation Bonus</h3>
        <p class="amount-font">${{ donationBonus }}</p>
      </v-col>
      <v-col cols="3">
        <h3>Donation Total</h3>
        <p class="amount-font">${{ donationTotal }}</p>
      </v-col>
    </v-row>

    <v-row class="mt-10" justify="center">
      <v-col cols="6">
        <v-btn color="#C2C1BF" block dark x-large rounded @click="$router.go(-1)">Back</v-btn>
      </v-col>
      <v-col cols="6">
        <v-btn color="#84C9C8" block dark x-large rounded @click="next">Next</v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <p>You have until August 31 to meet your goals. Classes must be taken using credit package or membership at participating studio.</p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'FitnessGoal',

  data() {
    return {
      pledgeCountLivemetta: 0,
      pledgeCountRayoga: 0,
    }
  },

  computed: {
    ...mapGetters({
      participants: 'participant/list',
    }),

    participant() {
      return this.participants[0]
    },
    rayogaSelected() {
      return this.$store.state.rayogaSelected
    },
    livemettaSelected() {
      return this.$store.state.livemettaSelected
    },
    donationAmount() {
      return this.$store.state.donationAmount.toFixed(2)
    },
    donationBonus() {
      let rayogaBonusModifier = 0
      let livemettaBonusModifier = 0

      switch(true) {
        case (this.pledgeCountRayoga > 0 && this.pledgeCountRayoga <= 10):
          rayogaBonusModifier = 0.5
          break;
        case (this.pledgeCountRayoga >= 11 && this.pledgeCountRayoga <= 24):
          rayogaBonusModifier = 0.75
          break;
        case (this.pledgeCountRayoga > 24):
          rayogaBonusModifier = 1
          break;
        default:
          rayogaBonusModifier = 0
          break;
      }

      switch(true) {
        case (this.pledgeCountLivemetta > 0 && this.pledgeCountLivemetta <= 10):
          livemettaBonusModifier = 0.5
          break;
        case (this.pledgeCountLivemetta >= 11 && this.pledgeCountLivemetta <= 24):
          livemettaBonusModifier = 0.75
          break;
        case (this.pledgeCountLivemetta > 24):
          livemettaBonusModifier = 1
          break;
        default:
          livemettaBonusModifier = 0
          break;
      }

      const rayogaBonus = this.donationAmount * rayogaBonusModifier
      const livemettaBonus = this.donationAmount * livemettaBonusModifier
      const bonus = (rayogaBonus + livemettaBonus).toFixed(2)

      return bonus
    },

    donationTotal() {
      return (parseFloat(this.donationAmount) + parseFloat(this.donationBonus)).toFixed(2)
    }
  },

  methods: {
    async next() {
      this.$store.state.rayogaCommitmentCount = this.pledgeCountRayoga
      this.$store.state.livemettaCommitmentCount = this.pledgeCountLivemetta

      await this.$store.dispatch('participant/patch', [this.participant.id, {
        pledgeCountLivemetta: this.pledgeCountLivemetta,
        pledgeCountRayoga: this.pledgeCountRayoga
      }]).then(() => {
        this.$router.push({ name: 'ServiceProject' })
      })
    }
  },

  beforeMount() {
    if (this.rayogaSelected) {
      this.pledgeCountRayoga = this.$store.state.rayogaCommitmentCount
    } else {
      this.pledgeCountRayoga = 0
    }

    if (this.livemettaSelected) {
      this.pledgeCountLivemetta = this.$store.state.livemettaCommitmentCount
    } else {
      this.pledgeCountLivemetta = 0
    }
  }
}
</script>

<style lang="scss">
.fitness-goal {
  .v-input {
    font-size: 24px;

    .v-text-field__slot {
      text-align: center;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
}
</style>