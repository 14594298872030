<template>
  <div class="complete-registration">
    <v-row>
      <v-col>
        <h1 class="mb-4">Complete Your Registration</h1>
        <p>To complete your registration, you'll need to add your payment information. </p>

        <p>100% of your donation will go to the charity you chose and again, LiveMetta or Ra Yoga will match your donation amount depending on your pledge. </p>

        <p>Our hope is that after all that we've been through over the past 3 years, this challenge will help us re-center our lives around what brings us joy and what is good in this world! </p>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12">
        <v-btn color="#84C9C8" :disabled="!checkout" :loading="!checkout" block dark x-large rounded @click="next">Complete Registration</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CompleteRegistration',

  data() {
    return {
      checkoutLink: null,
    }
  },

  computed: {
    ...mapGetters({
      participants: 'participant/list',
      checkouts: 'stripe-checkout/list'
    }),

    checkout() {
      return this.checkouts[this.checkouts.length - 1]
    },

    charityId() {
      return this.$store.state.selectedCharityId
    },

    donationAmount() {
      return this.$store.state.donationAmount
    },

    participant() {
      return this.participants[0]
    },
    canSubmit() {
      return this.joyText.length > 0
    }
  },

  methods: {
    async next() {
      window.location.href = this.checkout.url
    }
  },

  mounted() {
    if (this.$store.state.loginSession.id) {
      this.$store.state.selectedCharityId = this.$store.state.loginSession.charityId
      this.$store.state.donationAmount = this.$store.state.loginSession.donationAmount
    }

    let charitySlug = null

    switch(this.charityId) {
      case 1:
        charitySlug = 'PP'
        break
      case 2:
        charitySlug = 'CASA'
        break
      case 3:
        charitySlug = 'RM'
        break
      case 4:
        charitySlug = 'NAMI'
        break
      case 5:
        charitySlug = 'LGBTQ'
        break
      case 6:
        charitySlug = 'FS'
        break
    }

    console.log(this.charityId)

    const priceId = this.$store.state.priceIds.find((o) => o.slug === `${charitySlug}${this.donationAmount}`)

    this.$store.dispatch('stripe-checkout/create', {
      priceId: priceId.id,
    })
  }


}
</script>

<style lang="scss">

</style>