<template>
  <div class="charity-selector">
    <v-row>
      <v-col>
        <h1>Pick Your Charity</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <p>Please pick one charity that your DYP donation will benefit. Click a logo for a brief description of each cause. </p>
      </v-col>
    </v-row>
    <v-row v-if="isCharityFindPending">
      <v-col class="text-center">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-col>
    </v-row>
    <v-row v-if="!isCharityFindPending && charities.length > 0">
      <v-col
        class="charity-selector-item"
        :class="selectedCharityId === charity.id ? 'selected' : ''"
        cols="4"
        v-for="charity in charities"
        :key="charity.id"
        @click="selectCharity(charity)">
        <v-avatar color="#ffffff" style=" border: 2px solid #FCE4B0;" class="av-icon" size="80">
          <img style="height: unset; border-radius: unset; padding: 4px;" :src="charity.imageUrl" />
        </v-avatar>
        <h4>{{ charity.name }}</h4>
        <div class="radio-button"></div>
      </v-col>
    </v-row>
    <v-row v-if="!isCharityFindPending && charities.length > 0" justify="center" class="mt-10">
      <v-col cols="6">
        <v-btn color="#C2C1BF" block dark x-large rounded @click="$router.go(-1)">Back</v-btn>
      </v-col>
      <v-col cols="6">
        <v-btn color="#84C9C8" block dark x-large rounded @click="next">Next</v-btn>
      </v-col>
    </v-row>

    <v-dialog
      max-width="420"
      v-if="selectedCharity"
      v-model="selectedCharity">
      <v-card class="text-center steps-box" style="color: #187C78;">
        <v-card-title>
          <v-col><h3>{{ selectedCharity.name }}</h3></v-col>
        </v-card-title>
        <v-card-text>
          <p>{{ selectedCharity.text }}</p>
          <p><a :href="selectedCharity.link" target="_blank">{{ selectedCharity.link }}</a></p>
        </v-card-text>
        <v-card-actions>
          <v-col><v-btn rounded large depressed @click="closeCharity">Close</v-btn></v-col>
          <v-col><v-btn color="#84c9c8" large dark rounded depressed @click="selectCharityId(selectedCharity.id)">Select Charity</v-btn></v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CharitySelector',

  data() {
    return {
      selectedCharity: null,
      selectedCharityId: null,
    }
  },

  computed: {
    ...mapGetters({
      charities: 'charity/list',
      participants: 'participant/list',
    }),

    participant() {
      return this.participants[0]
    },

    isCharityFindPending() {
      return this.$store.state.charity.isFindPending
    }
  },

  methods: {
    selectCharity(item) {
      this.selectedCharity = item
    },
    selectCharityId(id) {
      this.selectedCharityId = id
      this.closeCharity()
    },
    closeCharity() {
      this.selectedCharity = null
    },
    async next() {
      this.$store.state.selectedCharityId = this.selectedCharityId
      await this.$store.dispatch('participant/patch', [this.participant.id, {
        charityId: this.selectedCharityId
      }]).then(() => {
        this.$router.push({ name: 'DonationForm' })
      })
    }
  },

  beforeMount() {
    this.$store.dispatch('charity/find')
    this.selectedCharityId = this.$store.state.selectedCharityId
  }
}
</script>

<style lang="scss">
.charity-selector-item {
  cursor: pointer;

  .av-icon {
    background-color: #fff;
    padding: 4px;

    img {
      height: unset;
    }
  }

  .radio-button {
    margin: auto;
    margin-top: 10px;
    height: 16px;
    width: 16px;
    border-radius: 100%;
    border: 2px solid #FCE4B0;
  }
}

.selected .radio-button {
  background-color: #F79387;
  border-color: #F79387;
}
</style>