<template>
  <div class="choose-studio">
    <v-row>
      <v-col>
        <h1 class="mb-5">Choose Your Studio(s)</h1>
        <p>Choose which studio(s) you will attend in order to accomplish your fitness goal.</p>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="10">
        <v-checkbox color="#F79387" v-model="livemettaSelected" class="studio-select livemetta">
        </v-checkbox>
      </v-col>
      <v-col cols="10">
        <v-checkbox color="#F79387" v-model="rayogaSelected" class="studio-select rayoga">
        </v-checkbox>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-10">
        <p>You have until August 31 to meet your goals. Classes must be taken using credit package or membership at participating studio.</p>
      </v-col>
    </v-row>
    <v-row class="mt-10" justify="center">
      <v-col cols="6">
        <v-btn color="#C2C1BF" block dark x-large rounded @click="$router.go(-1)">Back</v-btn>
      </v-col>
      <v-col cols="6">
        <v-btn color="#84C9C8" block dark x-large rounded @click="next">Next</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'ChooseStudio',

  data() {
    return {
      livemettaSelected: false,
      rayogaSelected: false,
    }
  },

  methods: {
    next() {
      this.$store.state.rayogaSelected = this.rayogaSelected
      this.$store.state.livemettaSelected = this.livemettaSelected
      this.$router.push({ name: 'FitnessGoal' })
    }
  },

  beforeMount() {
    this.rayogaSelected = this.$store.state.rayogaSelected
    this.livemettaSelected = this.$store.state.livemettaSelected
  }
}
</script>

<style lang="scss">
.studio-select {
  background-size: 82% 80px;
  background-position: 40px;

  .v-input__control {
    height: 80px;
  }

  .v-input__slot {
    height: 80px;
  }
}

.studio-select.rayoga {
  background-image: url('/rayoga-logo-brown.svg');
}

.studio-select.livemetta {
  background-image: url('/livemetta-horizontal.svg');
}
</style>