<template>
  <div class="service-project">
    <v-row>
      <v-col>
        <h1 class="mb-6">Pick Your Service Commitment</h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <p>Being of service to others is a big part of experiencing joy in our lives. Select from the list below what items you are committed to donating to OC & LA Rescue Mission.</p>
      </v-col>
    </v-row>

    <v-row class="dyp-card elevation-5 py-5">
      <v-col cols="12" v-for="project in serviceProjects" :key="project.id">
        <v-row
          @click="selectProject(project.id)"
          class="service-project-item"
          :class="selectedProject === project.id ? 'selected' : ''"
          align="center">
          <v-col cols="2">
            <div class="radio-button"></div>
          </v-col>
          <v-col cols="10">
            <h4>{{ project.text }}</h4>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <p>All donations will be collected at Ra Yoga or Live Metta starting August 1st. While you are committing to one selection, feel free to donate any of the above items.</p>
      </v-col>
    </v-row>

    <v-row class="mt-10" justify="center">
      <v-col cols="6">
        <v-btn color="#C2C1BF" block dark x-large rounded @click="$router.go(-1)">Back</v-btn>
      </v-col>
      <v-col cols="6">
        <v-btn color="#84C9C8" :disabled="selectedProject === null" block dark x-large rounded @click="next">Next</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ServiceProject',

  data() {
    return {
      selectedProject: null,
    }
  },

  computed: {
    ...mapGetters({
      serviceProjects: 'service-project/list',
      participants: 'participant/list',
    }),

    participant() {
      return this.participants[0]
    },
  },

  methods: {
    selectProject(id) {
      this.selectedProject = id
    },
    async next() {
      this.$store.state.selectedServiceProjectId = this.selectedProject

      await this.$store.dispatch('participant/patch', [this.participant.id, {
        serviceProjectId: this.selectedProject
      }]).then(() => {
        this.$router.push({ name: 'JoyCommitmentForm' })
      })
    }
  },

  beforeMount() {
    this.$store.dispatch('service-project/find')
    this.selectedProject = this.$store.state.selectedServiceProjectId
  }
}
</script>

<style lang="scss">
.service-project {
  .service-project-item {
    cursor: pointer;
    text-align: left;

    p {
      margin-bottom: 0;
    }
  }

  .radio-button {
    margin: auto;
    height: 16px;
    width: 16px;
    border-radius: 100%;
    border: 2px solid #FCE4B0;
  }


  .selected .radio-button {
    background-color: #F79387;
    border-color: #F79387;
  }
}
</style>