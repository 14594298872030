<template>
  <div class="donation-form">
    <v-row>
      <v-col>
        <h1>Choose Your Donation Amount</h1>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="3" :class="amount === 10 ? 'selected' : ''">
        <div class="donation-selector" @click="selectAmount(10)">
          <div class="amount-container">
            $10
          </div>
          <div class="radio-button"></div>
        </div>
      </v-col>
      <v-col cols="3" :class="amount === 20 ? 'selected' : ''">
        <div class="donation-selector" @click="selectAmount(20)">
          <div class="amount-container">
            $20
          </div>
          <div class="radio-button"></div>
        </div>
      </v-col>
      <v-col cols="3" :class="amount === 30 ? 'selected' : ''">
        <div class="donation-selector" @click="selectAmount(30)">
          <div class="amount-container">
            $30
          </div>
          <div class="radio-button"></div>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <p>100% of your donation goes to Charity</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="details">
        <h3>How does gift matching work?</h3>

        <p>Gift matching is based on the fitness commitment (next selection). The more classes you commit to the greater we match! All classes must be attended before August 31, 2023.</p>

        <p><strong>Commit to:</strong></p>
        <ul>
          <li>&lsaquo;10 classes, 50% match of your gift. </li>
          <li>10-25 classes, 75% match of your gift.</li>
          <li>25+ classes, 100% match of your gift. </li>
        </ul>
      </v-col>
    </v-row>
    
    <v-row class="mt-10" justify="center">
      <v-col cols="6">
        <v-btn color="#C2C1BF" block dark x-large rounded @click="$router.go(-1)">Back</v-btn>
      </v-col>
      <v-col cols="6">
        <v-btn color="#84C9C8" :disabled="amount === null" block dark x-large rounded @click="next">Next</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'DonationForm',

  data() {
    return {
      amount: null,
    }
  },

  computed: {
    ...mapGetters({
      participants: 'participant/list',
    }),

    participant() {
      return this.participants[0]
    },
  },

  methods: {
    selectAmount(amount) {
      this.amount = amount;
    },
    async next() {
      this.$store.state.donationAmount = this.amount

      await this.$store.dispatch('participant/patch', [this.participant.id, {
        donationAmount: this.amount,
      }]).then(() => {
        this.$router.push({ name: 'ChooseStudio' })
      })
    }
  },

  beforeMount() {
    this.amount = this.$store.state.donationAmount
  }
}
</script>

<style lang="scss">
.donation-selector {
  cursor: pointer;

  .amount-container {
    margin: auto;
    padding: 15px 12px;
    box-sizing: border-box;
    background: #FFFFFF;
    border: 1px solid #FCE4B0;
    border-radius: 8px;
    font-size: 18px;
    font-weight: 700;
  }

  .radio-button {
    margin: auto;
    margin-top: 10px;
    height: 16px;
    width: 16px;
    border-radius: 100%;
    border: 2px solid #FCE4B0;
  }
}

.selected .radio-button {
  background-color: #F79387;
  border-color: #F79387;
}

.details {
  text-align: left;

  h3 {
    font-size: 23px;
    text-align: center;
    margin-bottom: 15px;
  }

  p {
    font-size: 16px;
    margin-bottom: 10px;
  }

  ul {
    font-size: 16px;
    padding-left: 35px;
  }
}
</style>