<template>
  <div class="joy-commitment-form">
    <v-row>
      <v-col>
        <h1 class="mb-4">Write Your Joy Commitment</h1>
        <p>Note that your submission will be seen by other participants so that we may all be an inspiration to each other.</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h3 class="text-left pb-4">Write Your Pledge</h3>
        <v-textarea
          counter="200"
          outlined
          v-model="joyText"
          placeholder="Example: I am going to go to the beach first thing in the morning 2 times per week to be in nature as I start my day."/>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="6">
        <v-btn color="#C2C1BF" block dark x-large rounded @click="$router.go(-1)">Back</v-btn>
      </v-col>
      <v-col cols="6">
        <v-btn color="#84C9C8" :loading="isCreatePending" :disabled="isCreatePending || !canSubmit" block dark x-large rounded @click="next">Next</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'JoyCommitmentForm',

  data() {
    return {
      joyText: '',
    }
  },

  computed: {
    ...mapGetters({
      participants: 'participant/list',
    }),

    participant() {
      return this.participants[0]
    },
    isCreatePending() {
      return this.$store.state['joy-submission'].isCreatePending
    },
    canSubmit() {
      return this.joyText.length > 0
    }
  },

  methods: {
    async next() {
      this.$store.dispatch('joy-submission/create', {
        name: `${this.participant.firstName} ${this.participant.lastName.charAt(0)}.`,
        text: this.joyText,
        participantId: this.participant.id
      }).then(() => {
        this.$router.push({ name: 'CompleteRegistration' })
      })
    }
  }
}
</script>

<style lang="scss">

</style>